.App {
	height: 100vh;
	color: navy;
	max-width: 100%;
	font-family: sans-serif;
	background-size: 100%;
	background-attachment: fixed;
	background-position: center;
	overflow-x: auto;
	overflow-y: auto;
	background-image: linear-gradient(180deg, hsla(28, 100%, 75%, 0.5) 25%, hsla(13, 100%, 65%, 0.5) 100%);
}

.register-text {
	font-size: 18px;
	text-align: center;
	margin-top: 15%;
	color: navy;
	text-shadow: 2px 2px 4px steelblue;
}
.register-text > h2 {
	text-shadow: 2px 2px 4px steelblue;
	border-bottom: 3px solid steelblue;
	color: navy;
	margin: 2%;
}
.register-img {
	width: 50%;
	margin: 0 auto;
}
.nav-link-wrapper {
	margin: 0 auto;
	width: 50%;
}
.registerLink {
	text-decoration: none;
	color: whitesmoke;
}

.nestedNav {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	padding-top: 2%;
}

.register-card {
	width: 50%;
	margin: 2%;
	background: #f4f1de;
	border: 3px double navy;
}

.navBar {
	text-align: right;
}

a {
	text-decoration: none;
}

.navLink {
	background: steelblue;
	color: navy;
	text-decoration: none;
	padding: 1%;
	border: 1px solid steelblue;
	border-radius: 10px;
	font-size: 20px;
}

.navLink2 {
	text-decoration: none;
	color: rgb(231, 111, 81);
	padding: 1%;
	border: 1px solid rgba(231, 111, 81, 0.3);
	border-radius: 10px;
	font-size: 20px;
	margin: 5%;
	margin-top: 40px;
	background: rgba(224, 122, 95, 0.1);
}

.active {
	background: steelblue;
	color: navy;
	text-decoration: underline overline;
}

.active2 {
	background: navy;
	color: white;
	text-decoration: underline overline;
}
.logo {
	width: 7rem;
}

.nonForm {
	text-align: center;
}

.formButton {
	margin-left: 46%;
	background: #f4f1de;
}

.formButton2 {
	display: flex;
	justify-content: flex-end;
	margin: 0 auto;
	margin-left: 80%;
}

.adminForms {
	box-shadow: 10px 15px 10px #555;
	background: rgba(70, 130, 180, 0.6);
	padding: 2%;
	color: rgba(0, 0, 128, 0.4);
	font-size: 18px;
	text-shadow: -1px 0 rgba(70, 130, 180, 0.2), 0 1px rgba(70, 130, 180, 0.2), 1px 0 rgba(70, 130, 180, 0.2),
		0 -1px rgba(70, 130, 180, 0.2);
	border-radius: 5px;
}

.formWrapper {
	box-shadow: 10px 15px 10px rgba(106, 90, 205, 0.8);
	background: rgba(70, 130, 180, 0.6);
	border: 5px groove rgba(0, 0, 128, 0.4);
	padding: 2%;
	color: rgba(0, 0, 128, 0.4);
	text-shadow: -1px 0 rgba(70, 130, 180, 0.2), 0 1px rgba(70, 130, 180, 0.2), 1px 0 rgba(70, 130, 180, 0.2),
		0 -1px rgba(70, 130, 180, 0.2);
	border-radius: 10px;
	border-bottom-left-radius: 50px;
	border-top-right-radius: 50px;
}

.search-bar {
	width: 50%;
	margin: 2%;
}

.col {
	margin: 2%;
}

.imgDiv {
	display: flex;
	margin: 0 auto;
	justify-content: space-between;
	width: 100%;
}
.img {
	border: 5px double steelblue;
	margin: 20% 10%;
	font-size: 325%;
}
.names {
	margin: 10% auto;
}

a:hover {
	background: rgb(231, 111, 81);
	text-decoration: none;
	color: whitesmoke;
}

.buttonGroup {
	display: flex;
	width: 75%;
	margin: 0 auto;
	margin-top: 5%;
	height: 50px;
}

.task-nav {
	border-bottom: 1px solid gray;
	display: flex;
	justify-content: space-between;
	margin: 3% 0;
}

.li {
	padding-top: 5%;
	width: 100%;
}

.task-btns {
	display: flex;
	padding-top: 2%;
	justify-content: flex-end;
}

.editFormCollapse {
	display: flex;
	flex-direction: column;
}

.cards {
	background: #f4f1de;
	box-shadow: 10px 15px 10px rgba(106, 90, 205, 0.8);
	border-radius: 10px;
	border-top: 5px groove steelblue;
	border-bottom: 5px groove steelblue;
	border-right: 1px solid steelblue;
	border-left: 1px solid steelblue;
}

.completed {
	display: none;
}

.title-complete{
	display: flex;
	width: 100%;

}
.text-span{
display: flex;
width: 100%;
align-items: baseline;
} 
.icon-span{
	display:flex;
	justify-content: flex-end;
	align-items:baseline;
	margin-right: 5%;
	width: 25%;
}


@media( max-width: 800px) {

	.nestedNav{
		display: flex;
		flex-direction: column;
		align-items: center;
		
	}
}